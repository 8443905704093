.aba-body {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
    padding: 30px;
}

.aba-heading {
    color: #08AEB0;
}

.aba-button {
    color: rgb(0, 73, 137);
}