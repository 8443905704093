@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Exo:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
div.coming-soon-overlay {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    float: left;
    top: 270px;
    width: 1010px;
    height: 773px;
    background: rgba(0,0,0,0.2);
    z-index: 999;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    padding-top: 200px;
    font-size: 100px;
    font-family: fantasy;
    margin-left: -13px;
}
.contact-us-fields-container-v1 .ms-TextField-wrapper {
    border: 1px solid #808080;
    padding: 10px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border-radius: 10px;
    margin-bottom: 10px;
}

.contact-us-fields-container-v1 .ms-TextField-wrapper input[type=text] {
    border: 0px;
}

h1 {
    font-weight: bold !important;
    color: #1b3b6a;
    margin-bottom: 0;
    padding-bottom: 0;
}

h2,
h3,
h4 {
    color: #1b3b6a;
}

.text-bold {
    font-weight: bold;
}

.text-blue {
    color: #1b3b6a;
    /*text-align: center;*/
    margin-top: 0;
}

.Header {
    text-align: center;
}

.MidDiv {
    text-align: justify-all;
}

.element1 {
    float: left;
    width: 47%;
}

.element2 {
    float: right;
    width: 47%;
}

* {
    box-sizing: border-box;
}





.column {
    width: 33.33%;
    padding-left: 20px;
    margin-left: 2rem;
}

.column>div {
    width: 100%
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.products-services-wrapper {
    display: flex;
}

.products-services-wrapper.multi-container,
.products-services-wrapper.integration-container {
    display: block;
}


.products-services-wrapper>div.product-card,
.integration-container>.integration-card {
    border-radius: 10px;
    border: 2px solid #1b3b6a;
    padding: 5px;
    height: 90px !important;
    width: 22%;
    margin-right: 3%;
    display: inline-flex;
    margin-right: 48px;
}

.integration-card .integration-card-heading {
    height: 45px;
}

.integration-container>.integration-card {
    flex-flow: column;
    height: 100px !important;
}

.products-services-wrapper.multi-container>div.product-card,
.products-services-wrapper.integration-container>div.integration-card {
    margin-bottom: 20px;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px
}

.integration-container>div.integration-card .switch-container {
    width: 50%;
    height: 53%;
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.justify-end {
    justify-content: flex-end;
}

.products-services-wrapper>div.product-card:last-child,
.products-services-wrapper.multi-container>div.product-card:nth-child(4n+4),
.products-services-wrapper.integration-container>div.integration-card:nth-child(4n+4) {
    margin-right: 0px;
}


/*    {
        margin-right: 0px;
    }
*/
.product-image {
    height: 100%;
}

.product-image img {
    width: 80%;
}

/**/
.products-services-wrapper>div.product-card>div:first-child {
    width: 30%;
}

.products-services-wrapper>div.product-card>div:last-child {
    width: 70%;
    margin-left: 1%;
}

.products-services-wrapper>div.product-card>div:last-child h4 {
    margin: 0px;
    padding: 0px;
    height: 50px;
}

.products-services-wrapper>div.product-card>div .switch-container {
    text-align: right;

}

.d-flex.product {
    justify-content: flex-end;
}

/*.infoIcondiv {
    width: 20%;
}*/
/* check-toggle css*/
.products-services-wrapper>div.product-card>div .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
}


.tooltip {
    position: relative;
    display: inline-block;
    float: right;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #1b3b6a;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px 10px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;

}

.products-services-wrapper.integration-container>div.integration-card:nth-child(4n+4) .tooltip .tooltiptext {
    right: 21px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.infoIcon {
    background-color: #0D98BA;
    border: none;
    color: white;
    padding: 1px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    /*margin-bottom: 30px !important;*/
}

.infoIcon.tooltip {
    margin-left: 5px;
}

.proImag {
    width: 50%;
    height: 50px !important;
}

.proImag img {
    max-width: 85%;
}

.integration-img-wrapper {
    height: 55px !important;
}

.payment-section {
    text-align: right;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
}

.payment-section>span,
.mx-auto {
    margin: 0 10px;
}

.ms-TextField-fieldGroup::before {
    right: auto !important;
}

.splitter {
    width: 100%;
}
.confirm_btn {
    margin-left: 30px;
}

.ms-Grid-row.first-title-question-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.divider {
    border: 1px solid lightgray;
    height: 0;
    width: 100%;
}

.ft-section {
    margin: 10px 0;
}

.ft-section,
fieldset {
    margin: 10px 0;
}

.known-risk-policies {
    margin: 20px;
}

.ft-data-field {
    margin: 10px 0;
}

.ft-party {
    margin: 10px 0;
}

.ft-attachment-link {
    text-decoration: none;
    color: black;
}

.ft-attachment-link:hover {
    font-weight: bold;
}

.underwriting-disclaimer-container {
    color: rgb(164, 38, 44);
}

.address-ft-convert-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0078d4;
    border-radius: 50px;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.address-ft-convert-popover i {
    color: white;
    line-height: 2px;
    font-size: 18px;
}

.address-heading-container {
    display: flex;
    align-items: center;
}

.address-heading-container label {
    margin-right: 10px;
}

.ft-address-tooltip-container {
    display: flex;
    flex-direction: column;
}
.add-buyer-container, .remove-buyer-container {
    display: flex;
    justify-content: end;
}

.remove-buyer-container {
    flex-direction: row;
}
.first-title-auth-row {
    max-width: 360px;
    margin: 10px 0;
}
.ft-container {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.firsttitle-page.ms-Grid-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
}

.firsttitle-page.ms-Grid-row:last-child {
    border-bottom: 0px;
}

.firsttitle-page.row.wrapper {
    padding: 40px 40px 40px;
}
.create-pexa-workspace {
    background-color: white;
    margin: 0px auto !important;
    max-width: 1020px;
}

.create-pexa-content {
    display: flex;
    flex-direction: column;
    padding: 30px 0 !important;
    line-height: 20px;
}

.create-pexa-step {
    padding-top: 40px;
}

.create-pexa-content h4 {
    line-height: 20px;
}

.pexa-data-list {
    width: 100%;
    font-size: 14px;
    text-align: left;
    line-height: 25px;
}

.has-bottom-border {
    border-bottom: 1px solid lightgray;
}

.pexa-data-list td {
    padding: 5px 0px;
}

td.pexa-name-list {
    padding: 0;
}

.pexa-name-list div {
    padding: 3px 0;
}

.pexa-data-key {
    color: black;
}

.create-pexa-tip {
    margin: 15px 0 15px 0;
}

.validate-land-title-result {
    width: 800px;
    font-size: large;
}

.validate-pexa-workspace {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.validate-pexa-info-group {
    padding: 10px;
}

.validate-pexa-participant {
    padding: 3px;
}

.invite-participant-item {
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 800px;
    margin: 10px !important;
}

.invite-participant-field {
    padding: 10px;
    margin-left: 0px !important;
    border-bottom: 1px dotted lightgray;
    border-top: 1px dotted lightgray;
}

.select-participant {
    padding: 15px 10px 20px 10px;
    border-top: none;
}

.remove-button {
    float: right;
    background-color: lightgray;
}

.workspace-summary-modal {
    width: 700px;
    overflow-y: hidden;
    max-height: 100vh;
}
.workspace-summary-modal-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;    
}
.workspace-summary-tr > td {
    padding: 5px 1px 5px 15px;
}
.workspace-summary-modal {
    width: 700px;
    overflow-y: hidden;
    max-height: 100vh;
}

.workspace-summary-modal-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
}

.workspace-summary-tr > td {
    padding: 5px 1px 5px 15px;
}

.workspace-summary-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    width: 100%;
}
.invite-participants-modal {
    overflow-y: auto;
    max-height: 100vh;
    min-width: 550px !important;
}

.invite-participants-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    width: 98% !important;
}
.tittle {
    text-align: center;
}

.pexa-data-key {
    color: black;
}

.pexa-new-settlement {
    padding: 5px 0px 5px 50px!important;
    color: black;
    font-weight:normal;
}
.pexa-settlement-date-time-confirmation-table {
    border-collapse: collapse;
    width: 80%;
}
.pexa-settlement-date-time-confirmation-table td,
.pexa-settlement-date-time-confirmation-table th {
    border: 1px solid black;    
}
.saveButton {
    float:left;
   background-color:#0acf38 !important;
   color: white !important;
}
.Mui-disabled{
    background-color:#686969 !important;
}

.party-details {
    width: 100%;
    text-align: left;
    line-height: 25px;
    padding-bottom: 15px;
}

.party-details-heading {
    font-size:15px;
}
.MuiRadio-colorSecondary.Mui-checked  {
    color:#004989 !important;
}
.input-invalid {
      border: "2px solid #dc7070 !important";
      border-radius:"6px !important"
}
.userDeatils{
    width: 100% !important;
    background-color: black;
}
  
.document-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3px;
    gap: 3px;
}
.doc-header {
    font-weight: bold;
    font-size: 15px !important;
    padding-bottom: 15px;
}

.doc-row {
display: inline-block !important;
font-size: 1rem;
}
.doc-row span {
    padding-left:10px;

}
.doc-return-button{
    width:75% !important;   
}

.create-order-form {
    margin: 10px 40px 10px 40px;
    padding: 30px;
    background: #FFFFFF;
    font-family: Roboto Helvetica Arial sans-serif;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
 
}

.header {
    font-size: 25px;
    text-align: center;
    font-weight: 700;    
}

.ecaf-data-list {
    margin: auto;
}

.ecaf_addtional_instructions textarea{
    min-height:120px;
}

.send-to-ecaf-tr{
    text-align:center;
    width:120px;
}

.ecaf-data-key,.ecaf-data-value{
    padding-bottom:15px;
}

.remove-btn {
    float: right;
    margin-right: 10px;
    text-transform: capitalize !important;
}
.add-btn {
    margin-top: -30px !important;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 40px;
    text-transform: capitalize !important;
}
.section {
    margin:30px 5px 30px 5px;

}
.section-name {
    font-size: 18px;
    font-weight: 600; 
    font-family: Roboto Helvetica Arial sans-serif;
    margin-bottom: 15px;
}
.sub-section {
    margin: 20px 0 20px 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto Helvetica Arial sans-serif;
    text-transform: capitalize !important;
}

.flex-form {
    flex-grow: 1;  
}

.transaction-instruction {   
}

.transaction-date {
    font-size: 14px !important;
    margin-left: 20px !important;
    font-weight: 600 !important;
}

.transaction-types {
    margin:10px 0 10px 0;
}

.text-field {
    font-size: 14px !important;
    width: 90%;
}

.create-order-btn {
    font-size: 14px !important;
    float: right !important;
    margin-top: 20px !important;
    margin-right: 20px !important;
}

.table-row {
    display:table-row;
}

.table-cell {
    display: table-cell;
    width: 33%;
}
.cell-left{
    text-align:left;
}
.cell-center {
    text-align: center;
}
.cell-right {
    text-align: right;
}
.img-actionstep {
    max-width: 400px;
    width: 100%;
    height: auto;
}
.img-livesign {
    max-width: 216px;
    width: 100%;
    height: auto;
}
.img-arrow {
    max-width: 300px;
    width: 100%;
    height: auto;
}
.header{
    text-align:center;
}
.loading-section{
    margin:40px 0 100px;
}

.MuiGrid-item.validation-error {
    margin-top: 15px;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

.btn-primary {
    color: #fff;
    background-color: #1b3b6a;
    border-color: #1b3b6a;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.customer-wrapper{
    margin-bottom:30px;
}

.customer-wrapper .voi-review-status {
    display:inline-block;
    padding:5px 10px;
    background-color: #0dcaf0
}

.counter-sign-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.status-shape {
    width: auto !important;
    height: auto;
    display: inline-block;
    padding: 7px;
    border-radius:25px;
} 
.small-text{
    color:red;
}

.btn-countersign {
    display: block !important;
}
.btn-download-caf-Finalised {
    width: 100% !important;
    background-color: rgb(19, 78, 19) !important;
}
.caf-finalised-padding {
    padding-bottom: 20px;
}
.vertical-container {
    width: 99% !important;    
}

/*add comment*/
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

.btn-primary {
    color: #fff;
    background-color: #1b3b6a;
    border-color: #1b3b6a;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.customer-wrapper {
    margin-bottom: 30px;
}

.customer-wrapper .voi-review-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.counter-sign-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.small-text {
    color: red;
}

.order-link-wrapper {
    height: 30px;
}

a {
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(0, 73, 137);
            text-decoration-color: rgb(0, 73, 137);
}

a.order-link:hover {
    font-weight: 600;
}

.order-link-wrapper .order-link {
    text-decoration: none;
}

.order-link-wrapper .order-link {
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(0, 73, 137);
            text-decoration-color: rgb(0, 73, 137);
}

.orderlist-table .row-wrapper {
    height: 35px;
}

.orderlist-table .row-wrapper .order-id {
    text-align: left;
    color: rgb(0, 73, 137);
}

.customer-name {
    color: #333;
}

.order-link-status {
    text-decoration: none !important;
    color: white !important;
}

a.order-link-status:hover {
    font-weight: 600;
}

.orderlist-table .row-wrapper td {
    text-align: center;
}

.orderlist-table .cell-order-id {
    text-align: left;
}

.status-shape-orderlist {
    width: 65%;
    height: auto;
    display: inline-block;
    padding: 7px;
    border-radius: 25px;
}

.status-Draft {
    color: white;
    background: rgb(182, 180, 180);
}

.status-SentToClient, .status-NotStarted {
    color: white;
    background: rgb(220, 87, 36);
}

.status-InProgress, .status-NotReviewed {
    color: white;
    background: rgb(52, 122, 193, 0.97);
}

.status-Finalised, .status-Approved {
    color: white;
    background: rgb(19, 126, 19);
}

.status-RequiresReview, .status-Rejected {
    color: white;
    background: rgb(220, 87, 36)
}

.status-ReadyToCountersign {
    color: white;
    background: rgb(52, 122, 193, 0.97);
}

.status-Created {
    color: white;
    background: rgb(182, 180, 180);
}

.cell-customers {
    text-align: left;
}

.edit-order-modal {
    min-width: 70%;
    max-width: 90%;
    min-height: 10%;
    max-height: 90%;
    text-align: center
}

.view-customer-modal {
    min-width: 90%;
    max-width: 90%;
    min-height: 10%;
    max-height: 90%;
    text-align: center
}

.modal-position {
    height: 70% !important;
}

.btn-danger {
    background-color: '#f00';
    color: '#fff'
}
.modal-header {
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
    font-size: large;
}

.envelope-table
{
    margin-bottom: 10px;
}

.customer-table
{
    padding: 10px;
}

.envelope-block
{
    margin-bottom: 10px;
    border-bottom: 1px solid black;
}

.left-aligned-text
{
    text-align: left;
}

.center-aligned
{
    display: block;
    margin: auto;
}

.text-center
{
    text-align: center;
}

.app-font *
{
    font-family: Exo, 'Open Sans', Helvetica, Arial, sans-serif;
}
.modal-close-button{
    font-weight: bold;
    padding-bottom: 10px;
    float: right;
}
.modal-row-items {
    padding-top: 10px;
    padding-bottom: 10px;
}

.modal-position {
position: absolute;
top: 10px;
right: 100px;
bottom: 0;
left: 0;
z-index: 10040;
overflow: auto;
overflow-y: auto;
}
.modal-header {
    padding-top: 23px;
    padding-left: 10px;
    font-weight: bold;
    font-size: large;
}

.center-align {
    align-items: center;
}

.resend-notiifcation-button {
flex-wrap: wrap;
cursor: pointer;
width: 95px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.settlement-calculator {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.settlement-calculator .section {
    margin: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(235, 235, 235);
}

#settlementBorder {
    border: 1px solid;
    padding: 0px 8px;
}

.settlement-calculator .section-body {
    display: flex;
    flex-direction: column;
}

.settlement-calculator .state-row {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .state-row-no-padding {
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .heading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
}

.settlement-calculator .Paye-Cheque-Details {
    margin-left: 150px;
}

.settlement-calculator .settlement-background {
    background-color: #d8dbd9;
}

.settlement-calculator .settlement-border {
    border-width: thick;
    border-color: #d8dbd9;
}

.settlement-calculator .Paye-Amount {
    float: right;
    margin-right: 45px
}

.settlement-calculator .detail-row {
    padding-top: 3px;
    padding-bottom: 3px;
}

.settlement-calculator .detail-row>div {
    display: flex;
}

.settlement-calculator .sa-link {
    padding-top: 10px;
    padding-bottom: 10px;
}

.settlement-calculator .additional-header {
    padding-top: 5px;
    padding-bottom: 5px;
}

.settlement-calculator .left-align-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .right-align-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.settlement-calculator .dual-section-header {
    display: flex;
    justify-content: space-between;
}

.settlement-calculator .right-align {
    text-align: right;
}

.content-right-align{
    text-align: right;
}

.settlement-calculator .center-align {
    text-align: center;
}

.settlement-calculator .button {
    margin: 3px;
    border-radius: 3px;
    color: white;
    border: none;
}

.settlement-calculator .gray-button {
    background-color: gray;
    padding: 10px;
}

.settlement-calculator .red-button {
    background-color: rgb(212, 73, 70);
    padding: 10px;
}

.settlement-calculator .blue-icon-button {
    background-color: rgb(0, 120, 212);
    padding: 25px;
}

.settlement-calculator .rounded-blue-icon-button {
    background-color: rgb(0, 120, 212);
    padding: 20px;
    border-radius: 15px;
}

.settlement-calculator .topaz-blue-icon-button {
    background-color: white;
    border-radius: 15px;
    border: 2px solid;
    border-color: rgb(8, 174, 176);
    padding: 18px;
    color: rgb(8, 174, 176);
}

.settlement-calculator .show-hide-button {
    background-color: rgb(0, 120, 212);
    padding: 10px;
    margin-left: 10px;
}

.settlement-calculator .green-icon-button {
    background-color: rgb(82, 175, 82);
    padding: 25px;
}

.settlement-calculator .price-info {
    height: 100%;
    vertical-align: middle;
    text-align: right;
}

.settlement-calculator .separator {
    text-align: right;
    margin-left: 8px;
    margin-bottom: -10px;
}

.settlement-calculator .calculated {
    text-align: right;
    margin-left: 8px;
    margin-top: 15px;
}

.settlement-calculator .modal {
    border-radius: 5px;
}

.settlement-calculator .modal-title {
    font-weight: 600;
    font-size: 15px;
    color: black;
}

.settlement-calculator .modal-row {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .modal-label {
    min-width: 200px;
}

.settlement-calculator .modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.content-right-align {
    text-align: right;
}

.settlement-calculator .modal-body {
    min-width: 700px;
}

.settlement-calculator .loading-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.settlement-calculator .create-cheque-button {
    margin: 10px;
    margin-left: 25px;
}

.settlement-calculator .red {
    color: red;
}

.settlement-calculator .green {
    color: green;
}

.settlement-calculator .blue {
    color: blue;
}

.settlement-calculator .ms-Checkbox-checkbox {
    display: none;
}

.settlement-calculator .ibox-footer {
    display: flex;
}

.settlement-calculator .update-check-box {
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
}

.settlement-calculator .detail-row>.ms-Grid-col {
    display: unset;
}

.settlement-calculator .ibox-content {
    min-height: 0;
}

.loading-widget {
    text-align: center;
    padding: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* ibox (from old styles, this is to be removed and components replaced with fabric UI components */

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 0px 15px;
    min-height: 48px;
}

.ibox-content {
    clear: both;
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    min-height: 150px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}

.settlement-calculator-beta {
    background-color: #F83E3E !important;
}

.settlement-calculator-beta-list {
    color: #F83E3E;
}

.settlement-calculator-beta-title {
    margin-left: -16px;
}
.SettlementStatementBorder{
    border: solid;
    border-width: 0px 1px 1px 1px;
    padding: 10px;
}
#settlementThreeBorder{
    border: solid 1px;
    border-bottom: 0px;
}

/* #totalFundRequiredForSettlement{
    width: 350px;
} */

.settlement-calculator .margin-top20 {
    margin-top: -20px;
}

.header-text {
    color: #08AEB0;
}

.align-right {
    text-align: right;
}

.mui-font-override .MuiTypography-root {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 1em !important;
}

.adjustment-header-text {
    font-size: 15px;
    margin-left: 20px;
    font-weight: bold;;
}

.adjustment-header {
    border-bottom: 1px solid rgb(235, 235, 235);
}
.date-calc-detail-row {
	height: 3em;
	display: flex;
	align-items: stretch;
}

.date-calc-center {
	text-align: center;
	padding: 3px 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.topaz-blue-icon-button {
    background-color: white;
    border-radius: 15px;
    border: 2px solid;
    border-color: rgb(8, 174, 176);
    padding: 18px;
    color: rgb(8, 174, 176);
}

.settlement-calculator {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 0px 15px;
    min-height: 48px;
}

.ibox-content {
    clear: both;
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 0px 20px;
    min-height: 150px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footers {
    color: inherit;
    /* border-top: 1px solid #e7eaec; */
    font-size: 90%;
    background: #ffffff;
    padding: 5px 25px 10px;
    display: flex;
    justify-content: end;
}

.modal-row {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.calculationcenter {
    text-align: center;
}

.calculationRight {
    text-align: right;
}

.display-error {
    color: inherit;
    font-size: 90%;
    background: #ffffff;
    padding: 5px 250px 0px;
    display: flex;
}

.error-red-border {
    border: 1px solid red !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.topaz-blue-icon-button {
    background-color: white;
    border-radius: 15px;
    border: 2px solid;
    border-color: rgb(8, 174, 176);
    padding: 18px;
    color: rgb(8, 174, 176);
}

.settlement-calculator {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 0px 15px;
    min-height: 48px;
}

.ibox-content {
    clear: both;
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 0px 20px;
    min-height: 150px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footers {
    color: inherit;
    /* border-top: 1px solid #e7eaec; */
    font-size: 90%;
    background: #ffffff;
    padding: 5px 25px 10px;
    display: flex;
    justify-content: end;
}

.modal-row {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.calculationcenter {
    text-align: center;
}

.calculationRight {
    text-align: right;
}

.display-error {
    color: inherit;
    font-size: 90%;
    background: #ffffff;
    padding: 5px 250px 0px;
    display: flex;
}

.error-red-border {
    border: 1px solid red !important;
}
.matter-page {
  padding: 0px 10px;
}

.parter-cards-wrapper {
  margin: 30px 30px !important;
}

.parter-cards-wrapper > div.ms-Grid-col {
  float: left;
}

.partner-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-bottom: 1px solid #efefef;
}

.partner-logo.pexa-logo {
  position: relative;
}

.partner-card {
  min-height: 360px;
  margin: 20px 15px;
}

.partner-integrations {
  padding: 5px;
}

.partner-integrations a {
  text-align: center;
  text-decoration: none;
}

.partner-integrations a:hover {
  text-decoration: underline;
}

.partner-integrations a:not(.is-disabled) {
  color: black;
}

.error-page {
  margin: 30px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.integration-link-disable {
  color: currentColor;
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}

.update-workspace-element-container {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}

.update-workspace-element-container label {
    min-width: 150px;
}

.status-container {
    display: flex;
}
.announcement-header {
    font-size: 14px !important;
    width: 100%;
}

.promotion-announcement {
    background-color: #224D86 !important;
}

.promotion-new-product {
    background-color: #74C3BB !important;
}

.promotion-new-product * {
    color: #000000 !important;
}

.promotion-warning {
    background-color: #E64C52 !important;
}

/* this is to allow aligning the text inside of the promotion message bar, overriding an office ui styling */
.ms-MessageBar-text {
    display: block;
}
/* as the background color is konekta blue, all the text within it should be defaulted to white */
.announcement-header div, 
.announcement-header a, 
.announcement-header span, 
.announcement-header sp {
    color: #ffffff;
}
.announcement-header-margin {
    margin-left: 22px;  
    margin-right: 22px;
}
.announcement-heading {
 font-size: 14px;
 font-weight: bold;
}
.announcement-body {
font-size:12px !important;
padding-top:4px;
}
.center-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.gx-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.gx-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}
.helpTip {
    padding-left: 3px;
    padding-right: 3px;
}
.email-archiver-table {
    margin: 0px !important;
    width: 100%;
}
.tittle {
    text-align: center;
}

.blank_row {
    height: 40px !important;
    
}

.email-archiver-step {
    padding-top: 2px;
}

.horizontal-container ul li {
    width: 20%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: left
}
.progressBar {
    height: 10px !important;
 
}
.percentageValue {
    font-size: 0.7rem !important;
}
#top-frameless-nav {
    background-color: #1b3b6a;
    color: #ffffff;
    height: auto;
    margin: 0;
    padding: 0;
}

#top-frameless-nav a {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 1em 0 1em;
    height: 40px;
    color: #ffffff;
}
#top-frameless-nav a:hover {
    background: white;
    color: #1b3b6a;
    text-decoration: none;
}

#top-frameless-nav .align-right {
    text-align: right;
}

#top-nav-links {
    text-align: right;
    padding-top: 1.2em;
}

#top-nav-links a {
    color: black;
    padding: 0 0.5em 0.5em 0.5em;
    font-size: 1.1em;
}

#top-nav-links a img {
    padding-top: 0.6em;
}

.full-page-banner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-page-banner-actionstep-logo,
.full-page-banner-konekta-logo {
    height: 30px;
    padding: 8px 0 8px 0;
}

.full-page-banner-actionstep-logo { padding-right: 10px }
.full-page-banner-konekta-logo { padding-left: 15px; }

#page-header {
    border-bottom: 1px solid rgba(96,125,139,0.2);
    margin: 0;
    padding-bottom: 1em;
}
.konekta-footer {
    border-top: 1px solid #e7eace;
    padding-top: 3em;
}

.konekta-footer-text {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.konekta-footer-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4em;
}

.integration-partner-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.integration-partner-logos img {
    width: 120px;
    margin: 1em;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.integration-partner-logos img:hover {
    filter: none;
    -moz-filter: none;
    -webkit-filter: none;
    cursor: pointer;
}
body {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #333;
    /* overflow-x: hidden; */
}

html,
body {
    height: 100%;
    margin: 0px;
}

#root {
    height: 100%;
}

.rootDiv{
    width: 100%;
    height: 100%;
}
.rootDivEmbededIframe {
    overflow: hidden ;
}
.normal-page-wrapper {
    min-height: 100%;
    height: 100%;
    max-width: 1020px;
    /* overflow-x: hidden; */
    margin: auto;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
}

.full-page-wrapper,
.full-page-wrapper > div {
    width: 100%;
    height: 100%;
}

.full-page-wrapper div > div {
    height: calc(100% - 46px);
    padding: 0;
    margin: 0;
}

/* only the contact us page with the class trigger this css */
.full-page-wrapper > div:has(.contact-us-page) {
    height: auto;
    padding: 0;
    margin: 0;
    border: 0;
}

strong { font-weight: 600; }
h1 { font-weight: 500; }
h2 { font-weight: 600; }
h3 { font-weight: 500; }

.vertical-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.wrapper {
    padding: 40px 40px 100px;
}

/* ERROR BORDER */
.error-box {
    border-color: #a3262c;
}

.errorMessage {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0;
    padding-top: 5px;
    display: flex;
    align-items: center;
}

/* TEXT COLORS */
.text-info {
    color: #1b3b6a;
}

.text-danger {
    color: #f15a29;
}

/* MODALS */

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-body {
    position: relative;
    padding: 15px;
}
.ms-Modal-scrollableContent, .scrollableContent-157, .scrollableContent-164{
    height: 100% !important;
    max-height: 100% !important;
}

.modal-footer {
    margin-top: 0;
    border: none;
    padding: 10px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

/* ANIMATIONS */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

fieldset {
    border: none;
    -webkit-padding-start: unset;
            padding-inline-start: unset;
    -webkit-padding-end: unset;
            padding-inline-end: unset;
    -webkit-margin-start: unset;
            margin-inline-start: unset;
    -webkit-margin-end: unset;
            margin-inline-end: unset;
}

.button:not(.is-disabled) {
    background-color: #004989;
    color: #ffffff;
}

.button:not(.is-disabled):hover {
    background-color: #003979;
    color: #ffffff;
}

ul.extra-space li {
    padding-bottom: 1.1em;
}
.ecaf-auth-section{
    margin:auto;
}

.ecaf-auth-row {
    max-width: 360px;
    margin: 10px 0;
}

.back-to-home {
    text-decoration: underline;
    color: #134794;
    padding-left: 10px;
}

.ecaf-auth-wrapper{
    text-align:center;
}
/* RPI */
/* body {
    font-family: 'Poppins';
} */
.txtheader {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.formparagraph {
    margin-left: 10%;
    margin-right: 10%;
    font-size: larger;
    font-family: 'Poppins', Arial, sans-serif;
}

.text-field {
    height: 34px;
}

.maindiv {
    align-items: center;
    width: 628px;
    height: 89px;
    margin-left: 4%;
}

.txtinput {
    margin-left: 47px;
    padding-top: 6px;
}

.setplace {
    padding-top: 15px;
}

.lblset {
    margin-left: 49px;
    padding-top: 9px;
    color: #004989;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}


.setdivarea {
    background-color: #E5E5E5;
    height: auto;
    margin-left: 15%;
    margin-right: 18%;
}

.chkboxAdjust {
    margin-top: 9px;
}

.btncontroladdgap {
    /* padding-top: 82px; */
    text-align: center;
    padding-bottom: 15px;
    margin-left: 90px;
}

.btncontrolremovegap {
    text-align: center;
    padding-bottom: 15px;
    margin-left: 90px;
}

.btnstyle {
    background-color: #5555d1;
    color: whitesmoke;

}

.txtbackgroundolor {
    background-color: white;
    height: 40px;
    border-radius: 4px;
}
.reffereldiv{
    font-size: 19px;
    font-weight: bold;
    color: #004989;
    /* margin-left: 19px; */
    padding-top: 15px;
}
.lblsetservice{
    padding-top: 7px;
    margin-left: 49px;
    font-size: large;
    color: #004989;
    font-weight: 400;
}
.titlespan{
    margin-left: 12px;
}
.headdeclaimer{
    color: rgb(246, 65, 65);
    font-family: "'Poppins', Arial, sans-serif"
}
.declamercontent{
    /* padding-top: 75px; */
    margin-left: 71px;
    margin-right: 52px;
    font-family: 'Poppins', Arial, sans-serif;
}

.confirmationdivset {
    color: #004989;
    font-weight: bold;
    margin-top: 11px;
    font-size: larger;
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.divconfiormation{
    margin-top: 80px;
}

.divconfiormationspan{
    margin-top: 60px;
}
.confirmationmessage{
    text-align: left;
}
.fontstyle{
    font-weight: bold;
    font-style: italic;
    font-size: large;
}
.msgfontstyme{
    font-size: larger;
}
.txtmessage{
    margin-top: 11px;
    font-size: larger;
}
.txterror{
    font-style: italic;
    color:#F83E3E;
}
.failederrormessage{
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
    margin-top: 11px;
    font-size: larger;
    background-color:#F83E3E;
    height: 50px;
    border-radius: 3px;
}

.errorparagraf{
    padding-top: 15px
}
.buttonplacecenter{
    margin-right: 17px;
    margin-top: 60px;
    margin-left: 77%;
}

.buttonsize{
    height: 53px;
    width: 217px;
    border-radius: 8px;
    font-size: large;
    padding-top: 10px;
}
.lblsetserviceerror{
    padding-top: 7px;
    margin-left: 49px;
    font-size: smaller;
    color:#F83E3E;
}
.emailerror{
    margin-left: 10px;
    font-size: x-small;
    color: #f44336;
    font-weight: 600;
}
.failederror{
    padding-top: 15px;
}
.radiobtn{
    font-size: smaller;
    margin-left: 55px;
    color: #f44336;
}
.optionrequire{
    margin-top: 10px;
}

 /* Use these css for desable mouse hover icon on radio button and checkbox */
.MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
}
.MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color:transparent !important;
}
.MuiIconButton-colorPrimary:hover {
    background-color: transparent !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
    color: #004989 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.stewarttitle-page-container {
    font-family: 'Poppins', sans-serif;
    margin: 10px;
}

.st-main-title {
    font-size: 24px !important;
    text-align: center;
    font-weight: 700 !important;
    color: rgb(86, 198, 199, 1) !important;
}

.textbox-radius {
    border-radius: 10px;
}

.sub-title {
    text-align: center;
    font-weight: 600;
}

.main-heading {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: rgb(0, 73, 137);
}

.st-main-heading-subtitle {

    font-size: 18px !important;
    font-weight: 700 !important;
    color: rgb(0, 73, 137);
}



.sub-heading {
    font-size: 16px;
    font-weight: 700;
    color: rgb(0 73 137);
}

.grid-new-row {
    padding-top: 10px;
}

.st-selectfield {
    width: 30ch;
}

.st-datepicker {
    margin-left: 1;
    margin-right: 1;
    width: 200,
}

.st-grid-item__toggle {
    padding-top: 10px;
    font-family: 'Poppins', sans-serif;
}

.st-checkbox-label {
    width: 100%;
    color: rgb(0, 73, 137);
    padding-left: 5px;
    padding-top: 30px;
}

.stewarttitle-page-container .new-row {
    padding-top: 10px;
}

.stewarttitle-page-container .straight-row {
    display: flex;
    padding: 5px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.stewarttitle-page-container .paddingleft20 {
    padding-left: 20px;
}

.stewarttitle-page-container .aligned {
    display: flex;
    padding: 0px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.hide-content {
    display: none;
}

.stewarttitle-checkbox-error {
    display: inline-flex;
    align-items: center;
}

.stewarttitle-helpertext-error {
    font-size: 26px !important;
    margin-right: 4px !important;
    margin-top: 10px !important;
}

.stewarttitle-disabled {
    color: black !important;
    font-weight: bold !important;

}

.stewarttitle-disabled .ms-label {
    color: black !important;
    font-weight: bold !important;

}

.st-date {
    padding-top: 44px !important;
    width: 35ch;
}

.st-select-label {
    padding-bottom: 15px !important;
}

.st-typo__heading {
    color: rgb(0, 73, 137);
    font-style: bold;
}

.st_datepicker {
    .ms-TextField-fieldGroup {
        height: 55px !important;
    }
}

.st_radios,
.st_checkboxes {
    padding-left: 5px;
}

.st_tandc {
    padding-top: 10px;
    padding-left: 5px;
    font-weight: 700 !important;
}

.st_create-cheque-button {
    font-family: 'Poppins', sans-serif;
}

.st-textfield {
    width: 33ch;
}

@media (min-width: 850px) and (max-width: 1000px) {
    .st-textfield {
        width: 29ch;
    }

    .st-selectfield {
        width: 27ch;
    }
}

@media (max-width: 849px) {
    .st-textfield {
        width: auto;
    }

    .st-selectfield {
        width: auto;
    }
}
.refresh-pexa-container {
    background-color: white;
    max-width: 1020px;
}

.totalarea {
    height: auto;

}

.pexaRefresher-modal-body {
    max-width: 1350px;
}

.fssLineItems-th {
    font-weight: bold !important;
}

.maindivareas {
    align-items: center;
    width: 628px;
    height: 89px;
    display: flex;
}

.fsscontainer {
    position: relative;
}

.mainfssdiv {

    display: flex;
}

.mainfssheader {

    width: 100%;
    height: auto;
    display: flex;
    border-bottom: 1px solid #004989;
}

.btndeal {
    align-items: center;
    text-align: center;
    padding-left: 206px;
}

div#fssLineItems tr:nth-child(even) {
    background-color: whitesmoke;
}

div#dealingNumber tr:nth-child(even) {
    background-color: whitesmoke;
}


.buttonplaces {
    padding-top: 20px;
}

.errormessage {
    margin-top: 35px;
}

.buttonplaces-back {
    padding-top: 30px;
}

.btnplace {
    padding-top: 21px;
    margin-left: 177px;
}

.lbltext {
    padding-top: 9px;
    font-weight: bold;
    color: #004989;
}

.dealingNumberMessage {
    font-weight: bold !important;
}

.Mui-disabled {
    background-color: #ffffff1c !important;
}

.maindivareashead {
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
}

.maindivareas {
    margin-top: 10px;
    align-items: start;
    width: 100%;
    height: auto;
    display: flex;
}

.lblpexa {
    margin-left: 35%;
    color: #252525;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}

.lblastion {
    color: #252525;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}

.lblset {
    margin-left: 35%;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.headertext {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
    color: #1b3b6a;
}

.actionstep {
    margin-left: 35;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.divfound {
    margin-left: 58px;
}

.divdealnotfound {
    margin-left: 93px;
}

.divfounderror {
    margin-left: 58px;
    color: #F83E3E;
}

.divdealnotfounderror {
    margin-left: 93px;
    color: #F83E3E;
}

.container .btnsize {
    border-radius: 5px;
    width: 185px;
}

.container .fssbtnsize {
    border-radius: 5px;
}

.container .btkbtnsize {
    border-radius: 5px;

}

.divfssitemsheader {
    margin-left: 15%;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.divfssitems {
    margin-left: 15%;
    padding-top: 9px;
    color: #1C304A;
    font-family: 'Poppins', Arial, sans-serif;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .mainfssheader {
        overflow-wrap: break-word;

    }

    .mainfssdiv {
        overflow-wrap: break-word;
    }

    .divfssitems {
        margin-left: 2%;
        border: 1px solid red;
    }

    .divfssitemsheader {
        margin-left: 2%;
        border: 1px solid red;
    }

}
.legalaid-body {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.legalaid-body>button {
    margin: 10px;

}

.legalaid-search-container {
    padding-top: 20px;
}

.heading-grid {
    padding-left: 350px;
}

.dateRange-grid {
    padding-top: 50px;
    padding-left: 200px;
}

.textFileSizeLarge {
    width: 100%;
}

.cellWidthSmall {
    width: 10%
}

.textFileSizMedium {
    width: 100%;
}

.detailsTableHead {
    padding: 10px;
    width: 40%;
    background-color: #a9a4a466;
}

.detailsTableBody {
    border: 1px solid black;
}

.detailsTableCell {
    border: 1px solid black;
}

.detailsHeading {
    font-weight: bold;

}


.detailsTableSubHeading {
    padding-top: 10px;
}

.padding-grid {
    padding-top: 15px;
    padding-bottom: 30px;
}
.aba-body {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
    padding: 30px;
}

.aba-heading {
    color: #08AEB0;
}

.aba-button {
    color: rgb(0, 73, 137);
}
